import { template as template_d1b0b3976da246648165d342e6729cfc } from "@ember/template-compiler";
const FKText = template_d1b0b3976da246648165d342e6729cfc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
