import { template as template_aba098f63eca40deb69fdabaed734d0a } from "@ember/template-compiler";
const FKLabel = template_aba098f63eca40deb69fdabaed734d0a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
